@import url(https://cdn.uadigital.arizona.edu/lib/ua-brand-fonts/1.0.0/milo.min.css);
/*GLOBALS*/
/**screen widths**/
/**colors**/
.mouse_wave {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin: auto;
}

.scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.one {
  margin-top: 1px;
}

.one,
.two,
.three {
  -webkit-animation: mouse-wave 1s infinite;
  -moz-animation: mouse-wave 1s infinite;
}

.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
}

.two {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

@-webkit-keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.home_button_wrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 120px;
}

.home_button_wrap .cta_lead {
  font-weight: 700;
  line-height: 1.2;
  padding: 24px 0 0;
  width: 100%;
  font-size: 20px;
  color: #0c234b;
}

.home_button_wrap .cta_lead span {
  font-size: 30px;
  display: block;
  text-transform: uppercase;
}

.home_button_wrap a {
  border: none;
  border-radius: 0px;
  background-color: #ef4056;
  margin: 20px 24px;
  padding: 15px 24px;
  max-width: 432px;
  width: 45%;
  min-width: 270px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.home_button_wrap a:hover {
  background-color: #ab0520;
}

@media screen and (max-width: 600px) {
  .home_button_wrap .cta_lead {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 28px;
  }
  .home_button_wrap a {
    margin: 9px 24px;
  }
}

@media screen and (max-width: 500px) {
  .home_button_wrap .cta_lead {
    font-size: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 21px;
  }
}

@media screen and (max-width: 1054px) {
  .home_button_wrap {
    margin-bottom: 0;
  }
  .home_button_wrap a {
    width: 100%;
  }
}

/**mixins**/
@-webkit-keyframes topOpen {
  50% {
    top: 18px;
  }
  51% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 18px;
  }
}
@keyframes topOpen {
  50% {
    top: 18px;
  }
  51% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 18px;
  }
}

@-webkit-keyframes bottOpen {
  50% {
    bottom: 18px;
  }
  51% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    bottom: 18px;
  }
}

@keyframes bottOpen {
  50% {
    bottom: 18px;
  }
  51% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    bottom: 18px;
  }
}

@-webkit-keyframes topClosed {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 18px;
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  51% {
    top: 18px;
  }
  100% {
    top: 11px;
  }
}

@keyframes topClosed {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 18px;
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  51% {
    top: 18px;
  }
  100% {
    top: 11px;
  }
}

@-webkit-keyframes bottClosed {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    bottom: 18px;
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  51% {
    bottom: 18px;
  }
  100% {
    bottom: 11px;
  }
}

@keyframes bottClosed {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    bottom: 18px;
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  51% {
    bottom: 18px;
  }
  100% {
    bottom: 11px;
  }
}

@-webkit-keyframes topOpen600 {
  50% {
    top: 17px;
  }
  51% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 17px;
  }
}

@keyframes topOpen600 {
  50% {
    top: 17px;
  }
  51% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 17px;
  }
}

@-webkit-keyframes bottOpen600 {
  50% {
    bottom: 17px;
  }
  51% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    bottom: 17px;
  }
}

@keyframes bottOpen600 {
  50% {
    bottom: 17px;
  }
  51% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    bottom: 17px;
  }
}

@-webkit-keyframes topClosed600 {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 17px;
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  51% {
    top: 17px;
  }
  100% {
    top: 11px;
  }
}

@keyframes topClosed600 {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 17px;
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  51% {
    top: 17px;
  }
  100% {
    top: 11px;
  }
}

@-webkit-keyframes bottClosed600 {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    bottom: 17px;
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  51% {
    bottom: 17px;
  }
  100% {
    bottom: 11px;
  }
}

@keyframes bottClosed600 {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    bottom: 17px;
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  51% {
    bottom: 17px;
  }
  100% {
    bottom: 11px;
  }
}

.header {
  z-index: 100;
  margin: 0 auto 0 auto;
  padding: 3px 0 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  width: 100%;
  padding: 9px 0;
}

.header .az_site {
  display: block;
  position: relative;
  margin-left: 30px;
}

.header .az_site img {
  width: 150px;
}

.header .head_bttns {
  margin-right: 30px;
}

.header .head_bttns .menu_bttn {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  top: 3px;
  cursor: pointer;
  margin-left: 16px;
  border: none;
  outline: none;
  background: none;
  vertical-align: bottom;
}

.header .head_bttns .menu_bttn.white span {
  background-color: white;
}

.header .head_bttns .menu_bttn.blue span {
  background-color: #0c234b;
}

.header .head_bttns .menu_bttn span {
  background-color: white;
  height: 4px;
  width: 100%;
  display: block;
  position: absolute;
  top: 11px;
}

.header .head_bttns .menu_bttn span.arizona_blue {
  background-color: #0c234b;
}

.header .head_bttns .menu_bttn span.white {
  background-color: #ffffff;
}

.header .head_bttns .menu_bttn span.cool_grey {
  background-color: #e2e9eb;
}

.header .head_bttns .menu_bttn span.bloom {
  background-color: #ef4056;
}

.header .head_bttns .menu_bttn span:last-child {
  bottom: 11px;
  top: unset;
}

.header .head_bttns .menu_bttn.open span {
  -webkit-animation-timing-function: cubic-bezier(0, 0.15, 0, 0.48);
          animation-timing-function: cubic-bezier(0, 0.15, 0, 0.48);
  -webkit-animation-duration: 0.45s;
          animation-duration: 0.45s;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.header .head_bttns .menu_bttn.open span:first-child {
  -webkit-animation-name: topOpen;
          animation-name: topOpen;
}

.header .head_bttns .menu_bttn.open span:last-child {
  -webkit-animation-name: bottOpen;
          animation-name: bottOpen;
}

.header .head_bttns .menu_bttn.closed span {
  -webkit-animation-timing-function: cubic-bezier(0, 0.15, 0, 0.48);
          animation-timing-function: cubic-bezier(0, 0.15, 0, 0.48);
  -webkit-animation-duration: 0.45s;
          animation-duration: 0.45s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.header .head_bttns .menu_bttn.closed span:first-child {
  -webkit-animation-name: topClosed;
          animation-name: topClosed;
}

.header .head_bttns .menu_bttn.closed span:last-child {
  -webkit-animation-name: bottClosed;
          animation-name: bottClosed;
}

.header .head_bttns .home_bttn {
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 750px) {
  .header.bg_white {
    background-color: white;
  }
  .header.bg_blue {
    background-color: #0c234b;
  }
}

@media screen and (max-width: 600px) {
  .header .head_bttns {
    margin-right: 30px;
  }
  .header .head_bttns .menu_bttn {
    width: 32px;
    height: 37px;
    top: 4px;
    margin-left: 15px;
  }
  .header .head_bttns .menu_bttn.open span:first-child {
    -webkit-animation-name: topOpen600;
            animation-name: topOpen600;
  }
  .header .head_bttns .menu_bttn.open span:last-child {
    -webkit-animation-name: bottOpen600;
            animation-name: bottOpen600;
  }
  .header .head_bttns .menu_bttn.closed span:first-child {
    -webkit-animation-name: topClosed600;
            animation-name: topClosed600;
  }
  .header .head_bttns .menu_bttn.closed span:last-child {
    -webkit-animation-name: bottClosed600;
            animation-name: bottClosed600;
  }
  .header .home_bttn img {
    max-width: 30px;
    display: block;
    position: relative;
  }
}
/*# sourceMappingURL=Header.css.map */
/*GLOBALS*/
/**screen widths**/
/**colors**/
.mouse_wave {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin: auto;
}

.scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.one {
  margin-top: 1px;
}

.one,
.two,
.three {
  -webkit-animation: mouse-wave 1s infinite;
  -moz-animation: mouse-wave 1s infinite;
}

.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
}

.two {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

@-webkit-keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.home_button_wrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 120px;
}

.home_button_wrap .cta_lead {
  font-weight: 700;
  line-height: 1.2;
  padding: 24px 0 0;
  width: 100%;
  font-size: 20px;
  color: #0c234b;
}

.home_button_wrap .cta_lead span {
  font-size: 30px;
  display: block;
  text-transform: uppercase;
}

.home_button_wrap a {
  border: none;
  border-radius: 0px;
  background-color: #ef4056;
  margin: 20px 24px;
  padding: 15px 24px;
  max-width: 432px;
  width: 45%;
  min-width: 270px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.home_button_wrap a:hover {
  background-color: #ab0520;
}

@media screen and (max-width: 600px) {
  .home_button_wrap .cta_lead {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 28px;
  }
  .home_button_wrap a {
    margin: 9px 24px;
  }
}

@media screen and (max-width: 500px) {
  .home_button_wrap .cta_lead {
    font-size: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 21px;
  }
}

@media screen and (max-width: 1054px) {
  .home_button_wrap {
    margin-bottom: 0;
  }
  .home_button_wrap a {
    width: 100%;
  }
}

/**mixins**/
.home_profiles {
  max-width: 1200px;
  margin: 0 auto 120px auto;
  -webkit-column-count: 2;
          column-count: 2;
  -webkit-column-width: 530px;
          column-width: 530px;
}

@media screen and (max-width: 1054px) {
  .home_profiles {
    margin-top: 72px;
  }
}
/*# sourceMappingURL=Home.css.map */
/*GLOBALS*/
/**screen widths**/
/**colors**/
.mouse_wave {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin: auto;
}

.scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.one {
  margin-top: 1px;
}

.one,
.two,
.three {
  -webkit-animation: mouse-wave 1s infinite;
  -moz-animation: mouse-wave 1s infinite;
}

.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
}

.two {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

@-webkit-keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.home_button_wrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 120px;
}

.home_button_wrap .cta_lead {
  font-weight: 700;
  line-height: 1.2;
  padding: 24px 0 0;
  width: 100%;
  font-size: 20px;
  color: #0c234b;
}

.home_button_wrap .cta_lead span {
  font-size: 30px;
  display: block;
  text-transform: uppercase;
}

.home_button_wrap a {
  border: none;
  border-radius: 0px;
  background-color: #ef4056;
  margin: 20px 24px;
  padding: 15px 24px;
  max-width: 432px;
  width: 45%;
  min-width: 270px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.home_button_wrap a:hover {
  background-color: #ab0520;
}

@media screen and (max-width: 600px) {
  .home_button_wrap .cta_lead {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 28px;
  }
  .home_button_wrap a {
    margin: 9px 24px;
  }
}

@media screen and (max-width: 500px) {
  .home_button_wrap .cta_lead {
    font-size: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 21px;
  }
}

@media screen and (max-width: 1054px) {
  .home_button_wrap {
    margin-bottom: 0;
  }
  .home_button_wrap a {
    width: 100%;
  }
}

/**mixins**/
@-webkit-keyframes menuOpen {
  60% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@keyframes menuOpen {
  60% {
    left: 0;
  }
  100% {
    left: 0;
  }
}

@-webkit-keyframes menuFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes menuFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes menuClose {
  from {
    left: 0;
  }
  to {
    left: 120%;
  }
}

@keyframes menuClose {
  from {
    left: 0;
  }
  to {
    left: 120%;
  }
}

@-webkit-keyframes menuFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes menuFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.main_menu_container {
  background-color: #0c234b;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 120%;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 90;
}

.main_menu_container.open {
  -webkit-animation-timing-function: cubic-bezier(0.2, 1.13, 1, 0.99);
          animation-timing-function: cubic-bezier(0.2, 1.13, 1, 0.99);
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-name: menuOpen;
          animation-name: menuOpen;
}

.main_menu_container.open ul {
  -webkit-animation-delay: 0.65s;
          animation-delay: 0.65s;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-name: menuFadeIn;
          animation-name: menuFadeIn;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.main_menu_container.closed {
  -webkit-animation-timing-function: cubic-bezier(0.99, 1, 1.13, 0.2);
          animation-timing-function: cubic-bezier(0.99, 1, 1.13, 0.2);
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
  -webkit-animation-delay: 0.4;
          animation-delay: 0.4;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-name: menuClose;
          animation-name: menuClose;
}

.main_menu_container.closed ul {
  -webkit-animation-timing-function: cubic-bezier(0.83, 1.03, 1, 0.99);
          animation-timing-function: cubic-bezier(0.83, 1.03, 1, 0.99);
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: menuFadeOut;
          animation-name: menuFadeOut;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
}

.main_menu_container ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  height: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 100px 24px;
  max-width: 1200px;
  margin: 0 auto;
  opacity: 0;
}

.main_menu_container ul li {
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
  position: relative;
}

.main_menu_container ul li:hover {
  cursor: none;
}

.main_menu_container ul li:hover img {
  display: block;
}

.main_menu_container ul li:hover a span:first-child {
  text-decoration: underline;
}

.main_menu_container ul li img {
  pointer-events: none;
  display: none;
}

.main_menu_container ul li a {
  cursor: none;
  text-decoration: none !important;
  display: block;
  padding: 50px 40px;
}

.main_menu_container ul li a span {
  pointer-events: none;
}

.main_menu_container ul li a .student_name,
.main_menu_container ul li a .student_details {
  display: block;
  font-weight: bold;
}

.main_menu_container ul li a .student_name {
  color: #ffffff;
  font-size: 50px;
  letter-spacing: 10px;
}

.main_menu_container ul li a .student_details {
  color: #ef4056;
  font-size: 18px;
}

@media screen and (max-width: 800px) {
  .main_menu_container ul li {
    -webkit-flex-basis: 48%;
            flex-basis: 48%;
  }
}

@media screen and (max-width: 768px) {
  .main_menu_container ul {
    display: block;
  }
  .main_menu_container ul li {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .main_menu_container ul li a {
    padding: 20px 10px;
  }
  .main_menu_container ul li a .student_name {
    font-size: 32px;
    letter-spacing: 6px;
    margin-bottom: 4px;
  }
  .main_menu_container ul li a .student_details {
    font-size: 16px;
  }
}
/*# sourceMappingURL=Menu.css.map */
/*GLOBALS*/
/**screen widths**/
/**colors**/
.mouse_wave {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin: auto;
}

.scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.one {
  margin-top: 1px;
}

.one,
.two,
.three {
  -webkit-animation: mouse-wave 1s infinite;
  -moz-animation: mouse-wave 1s infinite;
}

.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
}

.two {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

@-webkit-keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.home_button_wrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 120px;
}

.home_button_wrap .cta_lead {
  font-weight: 700;
  line-height: 1.2;
  padding: 24px 0 0;
  width: 100%;
  font-size: 20px;
  color: #0c234b;
}

.home_button_wrap .cta_lead span {
  font-size: 30px;
  display: block;
  text-transform: uppercase;
}

.home_button_wrap a {
  border: none;
  border-radius: 0px;
  background-color: #ef4056;
  margin: 20px 24px;
  padding: 15px 24px;
  max-width: 432px;
  width: 45%;
  min-width: 270px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.home_button_wrap a:hover {
  background-color: #ab0520;
}

@media screen and (max-width: 600px) {
  .home_button_wrap .cta_lead {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 28px;
  }
  .home_button_wrap a {
    margin: 9px 24px;
  }
}

@media screen and (max-width: 500px) {
  .home_button_wrap .cta_lead {
    font-size: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 21px;
  }
}

@media screen and (max-width: 1054px) {
  .home_button_wrap {
    margin-bottom: 0;
  }
  .home_button_wrap a {
    width: 100%;
  }
}

/**mixins**/
/*# sourceMappingURL=ProfileBody.css.map */
/*GLOBALS*/
/**screen widths**/
/**colors**/
.mouse_wave {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin: auto;
}

.scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.one {
  margin-top: 1px;
}

.one,
.two,
.three {
  -webkit-animation: mouse-wave 1s infinite;
  -moz-animation: mouse-wave 1s infinite;
}

.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
}

.two {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

@-webkit-keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.home_button_wrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 120px;
}

.home_button_wrap .cta_lead {
  font-weight: 700;
  line-height: 1.2;
  padding: 24px 0 0;
  width: 100%;
  font-size: 20px;
  color: #0c234b;
}

.home_button_wrap .cta_lead span {
  font-size: 30px;
  display: block;
  text-transform: uppercase;
}

.home_button_wrap a {
  border: none;
  border-radius: 0px;
  background-color: #ef4056;
  margin: 20px 24px;
  padding: 15px 24px;
  max-width: 432px;
  width: 45%;
  min-width: 270px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.home_button_wrap a:hover {
  background-color: #ab0520;
}

@media screen and (max-width: 600px) {
  .home_button_wrap .cta_lead {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 28px;
  }
  .home_button_wrap a {
    margin: 9px 24px;
  }
}

@media screen and (max-width: 500px) {
  .home_button_wrap .cta_lead {
    font-size: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 21px;
  }
}

@media screen and (max-width: 1054px) {
  .home_button_wrap {
    margin-bottom: 0;
  }
  .home_button_wrap a {
    width: 100%;
  }
}

/**mixins**/
.hero_container {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
  overflow: hidden;
}

.hero_container .hero_text {
  max-width: 570px;
  color: #ffffff;
  margin-top: 23vh;
}

.hero_container .hero_text h1 {
  font-size: 100px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 16px;
}

.hero_container .hero_text span {
  font-size: 22px;
  font-weight: 700;
  display: block;
  border-bottom: 2px solid #8b0015;
  padding-bottom: 30px;
  line-height: 1.3em;
  margin-bottom: 24px;
}

.hero_container .hero_text p {
  font-size: 20px;
  line-height: 1.6em;
}

.hero_container .hidden_name {
  position: absolute;
  font-weight: 700;
  right: -50px;
  bottom: -20px;
  font-size: 240px;
  opacity: 0.21;
  color: #ffffff;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
}

@media (max-width: 972px) {
  .hero_container {
    background-position: right bottom;
  }
  .hero_container .hero_text {
    text-align: center;
    margin: 14vh auto 0 auto;
  }
  .hero_container .hero_text h1 {
    font-size: 58px;
    letter-spacing: 5.4px;
    padding-bottom: 12px;
  }
  .hero_container .hero_text span {
    font-size: 20px;
    border-bottom: none;
    padding-bottom: 0;
    line-height: 1.2em;
    margin-bottom: 24px;
  }
  .hero_container .hero_text p {
    font-size: 18px;
    line-height: 1.4em;
  }
  .hero_container .hidden_name {
    display: none;
  }
}
/*# sourceMappingURL=ProfileHero.css.map */
/*GLOBALS*/
/**screen widths**/
/**colors**/
.mouse_wave {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin: auto;
}

.scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.one {
  margin-top: 1px;
}

.one,
.two,
.three {
  -webkit-animation: mouse-wave 1s infinite;
  -moz-animation: mouse-wave 1s infinite;
}

.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
}

.two {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

@-webkit-keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.home_button_wrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 120px;
}

.home_button_wrap .cta_lead {
  font-weight: 700;
  line-height: 1.2;
  padding: 24px 0 0;
  width: 100%;
  font-size: 20px;
  color: #0c234b;
}

.home_button_wrap .cta_lead span {
  font-size: 30px;
  display: block;
  text-transform: uppercase;
}

.home_button_wrap a {
  border: none;
  border-radius: 0px;
  background-color: #ef4056;
  margin: 20px 24px;
  padding: 15px 24px;
  max-width: 432px;
  width: 45%;
  min-width: 270px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.home_button_wrap a:hover {
  background-color: #ab0520;
}

@media screen and (max-width: 600px) {
  .home_button_wrap .cta_lead {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 28px;
  }
  .home_button_wrap a {
    margin: 9px 24px;
  }
}

@media screen and (max-width: 500px) {
  .home_button_wrap .cta_lead {
    font-size: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 21px;
  }
}

@media screen and (max-width: 1054px) {
  .home_button_wrap {
    margin-bottom: 0;
  }
  .home_button_wrap a {
    width: 100%;
  }
}

/**mixins**/
.profile_qa {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  margin: 100px auto 50px;
}

.profile_qa .wrapper {
  position: relative;
}

.profile_qa .hidden_text {
  color: #1e5288;
  font-weight: 700;
  font-size: 240px;
  position: absolute;
  letter-spacing: 4px;
  left: 0;
  top: 0;
  opacity: 0.12;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
}

.profile_qa .questions_ans {
  position: relative;
  display: block;
  margin: 80px 0 0 auto;
  max-width: 1098px;
}

.profile_qa .questions_ans ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.profile_qa .questions_ans ul li {
  display: block;
  position: relative;
  color: #1e5288;
  font-size: 18px;
  -webkit-flex: 0 1 47%;
          flex: 0 1 47%;
  letter-spacing: 0.36px;
  margin-bottom: 70px;
}

.profile_qa .questions_ans ul li strong {
  font-weight: 700;
  display: block;
  margin-bottom: 20px;
}

.profile_qa .questions_ans ul li strong:before {
  content: '';
  position: relative;
  width: 22px;
  height: 6px;
  background: #ef4056;
  display: block;
  margin-bottom: 20px;
}

.profile_qa .questions_ans ul li span {
  display: block;
  line-height: 1.6em;
}

@media (max-width: 972px) {
  .profile_qa {
    margin: 0 auto 80px auto;
  }
  .profile_qa .hidden_text {
    font-size: 100px;
    text-align: center;
    position: relative;
    letter-spacing: 4px;
    margin: 0 auto;
    opacity: 0.25;
    -webkit-user-select: initial;
        -ms-user-select: initial;
            user-select: initial;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-writing-mode: horizontal-tb;
        -ms-writing-mode: lr-tb;
            writing-mode: horizontal-tb;
  }
  .profile_qa .questions_ans {
    margin: 40px 0 0 auto;
  }
  .profile_qa .questions_ans ul li {
    -webkit-flex: 0 1 100%;
            flex: 0 1 100%;
    margin-bottom: 60px;
  }
}

@media (max-width: 768px) {
  .profile_qa {
    margin: 0 auto 0px auto;
  }
  .profile_qa .hidden_text {
    font-size: 80px;
  }
}
/*# sourceMappingURL=ProfileQA.css.map */
/*GLOBALS*/
/**screen widths**/
/**colors**/
.mouse_wave {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin: auto;
}

.scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.one {
  margin-top: 1px;
}

.one,
.two,
.three {
  -webkit-animation: mouse-wave 1s infinite;
  -moz-animation: mouse-wave 1s infinite;
}

.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
}

.two {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

@-webkit-keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.home_button_wrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 120px;
}

.home_button_wrap .cta_lead {
  font-weight: 700;
  line-height: 1.2;
  padding: 24px 0 0;
  width: 100%;
  font-size: 20px;
  color: #0c234b;
}

.home_button_wrap .cta_lead span {
  font-size: 30px;
  display: block;
  text-transform: uppercase;
}

.home_button_wrap a {
  border: none;
  border-radius: 0px;
  background-color: #ef4056;
  margin: 20px 24px;
  padding: 15px 24px;
  max-width: 432px;
  width: 45%;
  min-width: 270px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.home_button_wrap a:hover {
  background-color: #ab0520;
}

@media screen and (max-width: 600px) {
  .home_button_wrap .cta_lead {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 28px;
  }
  .home_button_wrap a {
    margin: 9px 24px;
  }
}

@media screen and (max-width: 500px) {
  .home_button_wrap .cta_lead {
    font-size: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 21px;
  }
}

@media screen and (max-width: 1054px) {
  .home_button_wrap {
    margin-bottom: 0;
  }
  .home_button_wrap a {
    width: 100%;
  }
}

/**mixins**/
.profile_slider {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  background: #0c234b;
}

.profile_slider .slider_container {
  margin: 80px auto;
  width: 100%;
}

.profile_slider .slider_container .student_slider {
  display: block;
}

.profile_slider .slider_container .student_slider .student_slide {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
  opacity: 0.8;
}

.profile_slider .slider_container .student_slider .student_slide img,
.profile_slider .slider_container .student_slider .student_slide strong,
.profile_slider .slider_container .student_slider .student_slide span {
  display: block;
  margin: 0 auto;
  position: relative;
  font-weight: 700;
}

.profile_slider .slider_container .student_slider .student_slide strong {
  color: #ffffff;
  font-size: 28px;
  letter-spacing: 2.8px;
  margin: 14px auto 8px auto;
}

.profile_slider .slider_container .student_slider .student_slide span {
  color: #ef4056;
  font-size: 18px;
  letter-spacing: 0.64px;
}

.profile_slider .slider_container .student_slider .student_slide:hover {
  opacity: 1;
}

.profile_slider .slider_container .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.profile_slider .slider_container .slick-list,
.profile_slider .slider_container .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.profile_slider .slider_container .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.profile_slider .slider_container .slick-list:focus {
  outline: none;
}

.profile_slider .slider_container .slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.profile_slider .slider_container .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.profile_slider .slider_container .slick-track:before, .profile_slider .slider_container .slick-track:after {
  display: table;
  content: '';
}

.profile_slider .slider_container .slick-track:after {
  clear: both;
}

.profile_slider .slider_container .slick-loading .slick-track {
  visibility: hidden;
}

.profile_slider .slider_container .slick-loading .slick-slide {
  visibility: hidden;
}

.profile_slider .slider_container .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.profile_slider .slider_container .slick-slide img {
  display: block;
}

.profile_slider .slider_container .slick-slide.slick-loading img {
  display: none;
}

.profile_slider .slider_container .slick-slide.dragging img {
  pointer-events: none;
}

.profile_slider .slider_container [dir='rtl'] .slick-slide {
  float: right;
}

.profile_slider .slider_container .slick-initialized .slick-slide {
  display: block;
}

.profile_slider .slider_container .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.profile_slider .slider_container .slick-arrow.slick-hidden {
  display: none;
}

.profile_slider .slider_container .slick-prev,
.profile_slider .slider_container .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 43%;
  display: block;
  width: 50px;
  height: 70px;
  padding: 0;
  z-index: 1;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.profile_slider .slider_container .slick-prev:hover, .profile_slider .slider_container .slick-prev:focus,
.profile_slider .slider_container .slick-next:hover,
.profile_slider .slider_container .slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.profile_slider .slider_container .slick-prev:hover:before, .profile_slider .slider_container .slick-prev:hover:after, .profile_slider .slider_container .slick-prev:focus:before, .profile_slider .slider_container .slick-prev:focus:after,
.profile_slider .slider_container .slick-next:hover:before,
.profile_slider .slider_container .slick-next:hover:after,
.profile_slider .slider_container .slick-next:focus:before,
.profile_slider .slider_container .slick-next:focus:after {
  opacity: 1;
}

.profile_slider .slider_container .slick-prev.slick-disabled:before, .profile_slider .slider_container .slick-prev.slick-disabled:after,
.profile_slider .slider_container .slick-next.slick-disabled:before,
.profile_slider .slider_container .slick-next.slick-disabled:after {
  opacity: 0.25;
}

.profile_slider .slider_container .slick-prev:before, .profile_slider .slider_container .slick-prev:after,
.profile_slider .slider_container .slick-next:before,
.profile_slider .slider_container .slick-next:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '';
  position: absolute;
  background-color: #378dbd;
  height: 2px;
  width: 40px;
  margin: 0 auto;
  left: 0;
  right: 0;
  opacity: 0.8;
}

.profile_slider .slider_container .slick-prev {
  left: 0;
}

.profile_slider .slider_container .slick-prev:before {
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg);
  top: 21px;
}

.profile_slider .slider_container .slick-prev:after {
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  bottom: 22px;
}

.profile_slider .slider_container [dir='rtl'] .slick-prev {
  right: 0;
  left: auto;
}

.profile_slider .slider_container [dir='rtl'] .slick-prev:before {
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  top: 21px;
}

.profile_slider .slider_container [dir='rtl'] .slick-prev:after {
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg);
  bottom: 22px;
}

.profile_slider .slider_container .slick-next {
  right: 0;
}

.profile_slider .slider_container .slick-next:before {
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  top: 21px;
}

.profile_slider .slider_container .slick-next:after {
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg);
  bottom: 22px;
}

.profile_slider .slider_container [dir='rtl'] .slick-next {
  right: auto;
  left: 0;
}

.profile_slider .slider_container [dir='rtl'] .slick-next:before {
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg);
  top: 21px;
}

.profile_slider .slider_container [dir='rtl'] .slick-next:after {
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  bottom: 22px;
}

@media screen and (max-width: 1054px) {
  .profile_slider {
    margin-top: 120px;
  }
}

@media screen and (max-width: 768px) {
  .profile_slider {
    margin-top: 80px;
  }
}

@media screen and (max-width: 420px) {
  .profile_slider .slider_container .slick-list {
    margin: 0 -30px;
  }
}
/*# sourceMappingURL=ProfileSlider.css.map */
/*GLOBALS*/
/**screen widths**/
/**colors**/
.mouse_wave {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin: auto;
}

.scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.one {
  margin-top: 1px;
}

.one,
.two,
.three {
  -webkit-animation: mouse-wave 1s infinite;
  -moz-animation: mouse-wave 1s infinite;
}

.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
}

.two {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

@-webkit-keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.home_button_wrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 120px;
}

.home_button_wrap .cta_lead {
  font-weight: 700;
  line-height: 1.2;
  padding: 24px 0 0;
  width: 100%;
  font-size: 20px;
  color: #0c234b;
}

.home_button_wrap .cta_lead span {
  font-size: 30px;
  display: block;
  text-transform: uppercase;
}

.home_button_wrap a {
  border: none;
  border-radius: 0px;
  background-color: #ef4056;
  margin: 20px 24px;
  padding: 15px 24px;
  max-width: 432px;
  width: 45%;
  min-width: 270px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.home_button_wrap a:hover {
  background-color: #ab0520;
}

@media screen and (max-width: 600px) {
  .home_button_wrap .cta_lead {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 28px;
  }
  .home_button_wrap a {
    margin: 9px 24px;
  }
}

@media screen and (max-width: 500px) {
  .home_button_wrap .cta_lead {
    font-size: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 21px;
  }
}

@media screen and (max-width: 1054px) {
  .home_button_wrap {
    margin-bottom: 0;
  }
  .home_button_wrap a {
    width: 100%;
  }
}

/**mixins**/
.profile_video .vid_container {
  position: relative;
  display: block;
  max-width: 996px;
  width: 100%;
  height: auto;
  margin: 140px auto 100px auto;
  box-shadow: #0c234b 40px 40px 0;
}

@media (max-width: 972px) {
  .profile_video .vid_container {
    margin: 60px auto 80px auto;
    box-shadow: none;
  }
}
/*# sourceMappingURL=ProfileVideo.css.map */
/*GLOBALS*/
/**screen widths**/
/**colors**/
.mouse_wave {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin: auto;
}

.scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.one {
  margin-top: 1px;
}

.one,
.two,
.three {
  -webkit-animation: mouse-wave 1s infinite;
  -moz-animation: mouse-wave 1s infinite;
}

.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
}

.two {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

@-webkit-keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.home_button_wrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 120px;
}

.home_button_wrap .cta_lead {
  font-weight: 700;
  line-height: 1.2;
  padding: 24px 0 0;
  width: 100%;
  font-size: 20px;
  color: #0c234b;
}

.home_button_wrap .cta_lead span {
  font-size: 30px;
  display: block;
  text-transform: uppercase;
}

.home_button_wrap a {
  border: none;
  border-radius: 0px;
  background-color: #ef4056;
  margin: 20px 24px;
  padding: 15px 24px;
  max-width: 432px;
  width: 45%;
  min-width: 270px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.home_button_wrap a:hover {
  background-color: #ab0520;
}

@media screen and (max-width: 600px) {
  .home_button_wrap .cta_lead {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 28px;
  }
  .home_button_wrap a {
    margin: 9px 24px;
  }
}

@media screen and (max-width: 500px) {
  .home_button_wrap .cta_lead {
    font-size: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 21px;
  }
}

@media screen and (max-width: 1054px) {
  .home_button_wrap {
    margin-bottom: 0;
  }
  .home_button_wrap a {
    width: 100%;
  }
}

/**mixins**/
.video_container {
  width: 100vw;
  height: 100vh;
  background: white;
}
/*# sourceMappingURL=StudentProfile.css.map */
/*GLOBALS*/
/**screen widths**/
/**colors**/
.mouse_wave {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin: auto;
}

.scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.one {
  margin-top: 1px;
}

.one,
.two,
.three {
  -webkit-animation: mouse-wave 1s infinite;
  -moz-animation: mouse-wave 1s infinite;
}

.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
}

.two {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

@-webkit-keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.home_button_wrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 120px;
}

.home_button_wrap .cta_lead {
  font-weight: 700;
  line-height: 1.2;
  padding: 24px 0 0;
  width: 100%;
  font-size: 20px;
  color: #0c234b;
}

.home_button_wrap .cta_lead span {
  font-size: 30px;
  display: block;
  text-transform: uppercase;
}

.home_button_wrap a {
  border: none;
  border-radius: 0px;
  background-color: #ef4056;
  margin: 20px 24px;
  padding: 15px 24px;
  max-width: 432px;
  width: 45%;
  min-width: 270px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.home_button_wrap a:hover {
  background-color: #ab0520;
}

@media screen and (max-width: 600px) {
  .home_button_wrap .cta_lead {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 28px;
  }
  .home_button_wrap a {
    margin: 9px 24px;
  }
}

@media screen and (max-width: 500px) {
  .home_button_wrap .cta_lead {
    font-size: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 21px;
  }
}

@media screen and (max-width: 1054px) {
  .home_button_wrap {
    margin-bottom: 0;
  }
  .home_button_wrap a {
    width: 100%;
  }
}

/**mixins**/
@-webkit-keyframes profileFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes profileFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home_profile {
  max-width: 528px;
  width: 98%;
  display: block;
  position: relative;
  padding: 160px 20px 0;
  text-decoration: none;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  color: #0c234b;
  font-size: 18px;
  letter-spacing: 0.72px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, -webkit-transform 0.6s ease-in-out;
  transition: opacity 0.3s ease-in-out, transform 0.6s ease-in-out;
  transition: opacity 0.3s ease-in-out, transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
          break-inside: avoid;
}

.home_profile:first-of-type {
  margin-top: 0;
  padding: 0 20px 0;
}

.home_profile:first-of-type .student_name {
  top: 0;
}

.home_profile .student_img_cont img {
  -webkit-transform: translateY(90px);
          transform: translateY(90px);
}

.home_profile.in_view {
  opacity: 1;
}

.home_profile.in_view .student_img_cont img {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.home_profile:nth-child(odd) {
  margin-top: 0;
}

.home_profile:hover .student_info .student_img_cont img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.home_profile .student_name {
  display: block;
  font-size: 55px;
  letter-spacing: 11px;
  color: #0c234b;
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: 160px;
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.home_profile .student_info {
  max-width: 486px;
  margin: 0 0 0 auto;
}

.home_profile .student_info .student_img_cont {
  display: block;
  overflow: hidden;
}

.home_profile .student_info .student_img_cont img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  transition: 180ms cubic-bezier(0.02, 0.44, 1, 1);
}

.home_profile .student_info .student_descrip {
  display: block;
  -ms-grid-row-align: end;
  -webkit-align-self: end;
          align-self: end;
  font-weight: 700;
  color: #ef4056;
  font-size: 26px;
  letter-spacing: 1.04px;
  font-style: normal;
  margin-top: 20px;
  margin-bottom: 20px;
}

.home_profile .student_info .student_quote_cont {
  font-style: italic;
  display: block;
  border-top: 2px solid #1e5288;
  border-bottom: 2px solid #ef4056;
  text-align: center;
  line-height: 1.8em;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.home_profile .student_info .student_quote_cont.quote_in_view {
  opacity: 1;
}

.home_profile .student_info .student_quote_cont span {
  display: block;
  padding: 30px;
  border-width: 0;
  border-left: 2px solid;
  border-right: 2px solid;
  border-image: linear-gradient(#1e5288 0%, #1e5288 50%, #ef4056 51%, #ef4056 100%);
  border-image-slice: 1;
  position: relative;
}

.home_profile .student_info .student_quote_cont span:before, .home_profile .student_info .student_quote_cont span:after {
  background-color: #ffffff;
  position: absolute;
  bottom: 32%;
  font-size: 110px;
  font-weight: 700;
  height: 20px;
  padding-top: 50px;
}

.home_profile .student_info .student_quote_cont span:before {
  content: '\201C';
  left: -28px;
  color: #1e5288;
}

.home_profile .student_info .student_quote_cont span:after {
  content: '\201D';
  right: -20px;
  color: #ef4056;
}

@media screen and (max-width: 1200px) {
  .home_profile {
    max-width: 498px;
  }
  .home_profile .student_info {
    max-width: 446px;
  }
}

@media screen and (max-width: 1095px) {
  .home_profile {
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    padding: 90px 20px 0;
  }
  .home_profile:nth-child(odd) {
    margin-top: 0px;
  }
}

@media screen and (max-width: 560px) {
  .home_profile {
    box-sizing: border-box;
  }
  .home_profile .student_info {
    margin: auto;
  }
  .home_profile .student_name {
    display: block;
    max-width: 446px;
    margin: auto;
    letter-spacing: 9px;
    position: relative;
    -webkit-writing-mode: horizontal-tb;
        -ms-writing-mode: lr-tb;
            writing-mode: horizontal-tb;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: 0;
  }
}
/*# sourceMappingURL=HomeProfile.css.map */
/*GLOBALS*/
/**screen widths**/
/**colors**/
.mouse_wave {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin: auto;
}

.scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.one {
  margin-top: 1px;
}

.one,
.two,
.three {
  -webkit-animation: mouse-wave 1s infinite;
  -moz-animation: mouse-wave 1s infinite;
}

.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
}

.two {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

@-webkit-keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.home_button_wrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 120px;
}

.home_button_wrap .cta_lead {
  font-weight: 700;
  line-height: 1.2;
  padding: 24px 0 0;
  width: 100%;
  font-size: 20px;
  color: #0c234b;
}

.home_button_wrap .cta_lead span {
  font-size: 30px;
  display: block;
  text-transform: uppercase;
}

.home_button_wrap a {
  border: none;
  border-radius: 0px;
  background-color: #ef4056;
  margin: 20px 24px;
  padding: 15px 24px;
  max-width: 432px;
  width: 45%;
  min-width: 270px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.home_button_wrap a:hover {
  background-color: #ab0520;
}

@media screen and (max-width: 600px) {
  .home_button_wrap .cta_lead {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 28px;
  }
  .home_button_wrap a {
    margin: 9px 24px;
  }
}

@media screen and (max-width: 500px) {
  .home_button_wrap .cta_lead {
    font-size: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 21px;
  }
}

@media screen and (max-width: 1054px) {
  .home_button_wrap {
    margin-bottom: 0;
  }
  .home_button_wrap a {
    width: 100%;
  }
}

/**mixins**/
.home_hero {
  background-image: url(/static/media/Homepage_Hero.8543a666.jpg);
  background-size: cover;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.home_hero h1 {
  text-align: center;
  padding: 11% 0;
}

.home_hero h1 span {
  display: block;
  color: white;
}

.home_hero h1 span.small_text {
  font-size: 3.6vw;
  position: relative;
  letter-spacing: 9px;
  max-width: 1348px;
  width: 91%;
  overflow: hidden;
  margin: auto;
}

.home_hero h1 span.small_text:before, .home_hero h1 span.small_text:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 2px;
  background-color: white;
}

.home_hero h1 span.small_text:before {
  right: 65%;
}

.home_hero h1 span.small_text:after {
  left: 65%;
}

.home_hero h1 span.large_text {
  font-size: 12vw;
  letter-spacing: 35px;
  text-shadow: -1px 4px 0 #ef4056;
}

.home_hero h1 span.large_text span {
  display: inline;
  letter-spacing: 0;
}

@media screen and (max-width: 2300px) {
  .home_hero h1 span.small_text {
    width: 50%;
  }
}

@media screen and (max-width: 1800px) {
  .home_hero h1 span.small_text {
    width: 50%;
  }
  .home_hero h1 span.small_text:before {
    right: 70%;
  }
  .home_hero h1 span.small_text:after {
    left: 70%;
  }
}

@media screen and (max-width: 1400px) {
  .home_hero h1 span.large_text {
    letter-spacing: 28px;
  }
}

@media screen and (max-width: 1200px) {
  .home_hero h1 span.large_text {
    letter-spacing: 22px;
  }
}

@media screen and (max-width: 1024px) {
  .home_hero h1 span.small_text {
    font-size: 28px;
    letter-spacing: 8px;
  }
  .home_hero h1 span.large_text {
    font-size: 112px;
    letter-spacing: 10px;
  }
}

@media screen and (max-width: 768px) {
  .home_hero h1 {
    padding: 20% 0;
  }
  .home_hero h1 span.small_text:before, .home_hero h1 span.small_text:after {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .home_hero h1 span.small_text {
    font-size: 22px;
    letter-spacing: 4px;
  }
  .home_hero h1 span.large_text {
    font-size: 72px;
    letter-spacing: 9px;
  }
}

@media screen and (max-width: 500px) {
  .home_hero h1 span.small_text {
    font-size: 20px;
    letter-spacing: 3px;
  }
  .home_hero h1 span.large_text {
    font-size: 46px;
    letter-spacing: 8px;
  }
}
/*# sourceMappingURL=HomeHero.css.map */
/*GLOBALS*/
/**screen widths**/
/**colors**/
.mouse_wave {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  margin: auto;
}

.scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.one {
  margin-top: 1px;
}

.one,
.two,
.three {
  -webkit-animation: mouse-wave 1s infinite;
  -moz-animation: mouse-wave 1s infinite;
}

.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
}

.two {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

.three {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

@-webkit-keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.home_button_wrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 120px;
}

.home_button_wrap .cta_lead {
  font-weight: 700;
  line-height: 1.2;
  padding: 24px 0 0;
  width: 100%;
  font-size: 20px;
  color: #0c234b;
}

.home_button_wrap .cta_lead span {
  font-size: 30px;
  display: block;
  text-transform: uppercase;
}

.home_button_wrap a {
  border: none;
  border-radius: 0px;
  background-color: #ef4056;
  margin: 20px 24px;
  padding: 15px 24px;
  max-width: 432px;
  width: 45%;
  min-width: 270px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.home_button_wrap a:hover {
  background-color: #ab0520;
}

@media screen and (max-width: 600px) {
  .home_button_wrap .cta_lead {
    font-size: 18px;
    margin-bottom: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 28px;
  }
  .home_button_wrap a {
    margin: 9px 24px;
  }
}

@media screen and (max-width: 500px) {
  .home_button_wrap .cta_lead {
    font-size: 16px;
  }
  .home_button_wrap .cta_lead span {
    font-size: 21px;
  }
}

@media screen and (max-width: 1054px) {
  .home_button_wrap {
    margin-bottom: 0;
  }
  .home_button_wrap a {
    width: 100%;
  }
}

/**mixins**/
.home_descritption {
  box-sizing: border-box;
  padding: 90px 24px 28px;
  max-width: 1020px;
  width: 100%;
  margin: auto;
  text-align: center;
}

.home_descritption p {
  font-size: 20px;
  line-height: 1.5;
}
/*# sourceMappingURL=HomeContent.css.map */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
	v2.0 | 20110126
	License: none (public domain)
*/
/*GLOBALS*/
/**colors**/
* {
  margin: 0;
  font-family: MiloWeb, Verdana, Geneva, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-family: MiloWeb, Verdana, Geneva, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: fallback;
}

html {
  overflow-x: hidden;
}

body {
  line-height: 1;
  height: 100%;
  position: relative;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.main_container {
  width: 100%;
  background-color: #ffffff;
  margin: 0 auto;
}

.main_container.arizona_blue {
  background-color: #0c234b;
}

.main_container.white {
  background-color: #ffffff;
}

.main_container.cool_grey {
  background-color: #e2e9eb;
}

.main_container.bloom {
  background-color: #ef4056;
}

.main_container .wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  overflow: hidden;
}

.container {
  display: block;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1200px;
  margin: 0 auto;
}
/*# sourceMappingURL=App.css.map */
